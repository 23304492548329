import {
  Button,
  column,
  dateColumn,
  GridCellContent,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
  useGridTableApi,
} from "@homebound/beam";
import { percentCellContent, priceCellContent } from "src/components";
import { FundOverviewPageFundFragment, FundOverviewPageLotPartitionFragment } from "src/generated/graphql-types";
import { formatWithYear } from "src/utils/dates";

export type UnderwritingSummaryTableProps = {
  fund: FundOverviewPageFundFragment;
};

export function UnderwritingSummaryTable(props: UnderwritingSummaryTableProps) {
  const { fund } = props;

  const lotPartitions = fund.lots
    .flatMap((lot) => lot.partitions)
    .compact()
    .filter((partition) => partition.lot.fundContributionDate); // Only show ones that we have actually acquired

  const tableApi = useGridTableApi<Row>();

  return (
    <>
      <GridTable
        id="UnderwritingSummaryTable"
        rowStyles={rowStyles}
        sorting={{ on: "client" }}
        columns={createUnderwritingSummaryColumns()}
        rows={createLotRows(lotPartitions)}
        fallbackMessage="Underwriting Summary for the Investments in this Fund will show here."
        api={tableApi}
      />
      <Button onClick={() => tableApi.downloadToCsv("FundUnderwritingSummary.csv")} label="Download CSV" />
    </>
  );
}

type Row = SimpleHeaderAndData<FundOverviewPageLotPartitionFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function createLotRows(lots: FundOverviewPageLotPartitionFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(lots);
}

function createUnderwritingSummaryColumns(): GridColumn<Row>[] {
  const idColumn = column<Row>({
    header: () => "Lot Partition ID",
    data: ({ id }) => id,
    sticky: "left",
  });

  const blueprintIdColumn = column<Row>({
    header: () => "Blueprint ID",
    data: ({ blueprintProjectId }) => blueprintProjectId,
    sticky: "left",
  });

  const addressColumn = column<Row>({
    header: () => "Lot Address",
    data: ({ lot }) => lot.address?.street1,
    sticky: "left",
  });

  const purchasePriceColumn = column<Row>({
    header: () => "Purchase Price",
    data: ({ lot, underwrittenLotOwnershipBasisPoints }) =>
      amountGridCellContent(
        Math.round((lot.underwrittenPurchasePriceInCents * underwrittenLotOwnershipBasisPoints) / 100_00),
      ),
  });

  const lotMarkupColumn = column<Row>({
    header: () => "Lot Markup",
    data: ({ lot, underwrittenLotOwnershipBasisPoints }) =>
      amountGridCellContent(
        Math.round((lot.opcoSiteAcquisitionMarkupInCents * underwrittenLotOwnershipBasisPoints) / 100_00),
      ),
  });

  const fundContributionDateColumn = dateColumn<Row>({
    header: () => "Fund Contribution Date",
    data: ({ lot }) => (lot.fundContributionDate ? formatWithYear(lot.fundContributionDate) : null),
  });

  const softCostColumn = column<Row>({
    header: () => "Soft Cost",
    data: ({ underwrittenSoftCostInCents }) => amountGridCellContent(underwrittenSoftCostInCents),
  });

  const hardCostColumn = column<Row>({
    header: () => "Hard Cost",
    data: ({ underwrittenIndirectHardCostInCents, underwrittenDirectHardCostInCents }) =>
      amountGridCellContent(underwrittenIndirectHardCostInCents + underwrittenDirectHardCostInCents),
  });

  const builderFeeColumn = column<Row>({
    header: () => "Builder Fee",
    data: ({ underwrittenOpcoMarkupInCents }) => amountGridCellContent(underwrittenOpcoMarkupInCents),
  });

  const netSalesPriceColumn = column<Row>({
    header: () => "Net Sales Price",
    data: ({ underwrittenNetSalesPriceInCents }) => amountGridCellContent(underwrittenNetSalesPriceInCents),
  });

  const underwrittenIRRColumn = dateColumn<Row>({
    header: () => "IRR (Unlevered)",
    data: ({ lot }) =>
      lot.underwrittenUnleveredInternalRateOfReturnBasisPoints
        ? amountGridCellContent(lot.underwrittenUnleveredInternalRateOfReturnBasisPoints / 100, true)
        : null,
  });

  const underwrittenMOICColumn = dateColumn<Row>({
    header: () => "MOIC (Unlevered)",
    data: ({ lot }) => lot.underwrittenUnleveredMultipleOnInvestedCapital,
  });

  return [
    idColumn,
    blueprintIdColumn,
    addressColumn,
    fundContributionDateColumn,
    purchasePriceColumn,
    lotMarkupColumn,
    softCostColumn,
    hardCostColumn,
    builderFeeColumn,
    netSalesPriceColumn,
    underwrittenIRRColumn,
    underwrittenMOICColumn,
  ];
}

function amountGridCellContent(amount: number, usePercent = false): GridCellContent {
  return {
    alignment: "left",
    content: usePercent ? percentCellContent(amount) : priceCellContent(amount),
    value: usePercent ? amount : amount / 100, // Because this is the value printed by the CSV download
  };
}
