/* eslint-disable no-empty-pattern */
import {
  column,
  Css,
  GridColumn,
  GridDataRow,
  RowStyles,
  ScrollableContent,
  simpleHeader,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { Link } from "react-router-dom";
import { QueryTable } from "src/components";
import { FundsPageFundFragment, FundsPageFundsQuery, useFundsPageFundsQuery } from "src/generated/graphql-types";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { createFundDetailsUrl, createFundLoanManagementUrl } from "src/RouteUrls";

export function FundsPage() {
  useDocumentTitle("Funds");

  // Hacktastic/Placeholder until we need to do an earlier user query
  return FundsDataView({});
}

type FundsDataViewProps = Record<string, never>;

export function FundsDataView({}: FundsDataViewProps) {
  const query = useFundsPageFundsQuery({ variables: {} });

  return (
    <>
      <div css={Css.mr2.$}>
        <ScrollableContent virtualized>
          <QueryTable
            stickyHeader
            emptyFallback="No Funds found."
            query={query}
            columns={createColumns()}
            createRows={createRows}
            rowStyles={rowStyles}
            style={{ rowHeight: "fixed" }}
            as="virtual"
          />
        </ScrollableContent>
      </div>
    </>
  );
}

type Row = SimpleHeaderAndData<FundsPageFundFragment>;

function createColumns(): GridColumn<Row>[] {
  const idColumn = column<Row>({ header: "ID", data: (data) => data.id, w: "72px" });
  const nameColumn = column<Row>({
    header: "Name",
    data: (data) => data.name,
    w: "272px",
  });
  const detailsColumn = column<Row>({
    header: "",
    data: (data) => createDetailsLink(data.id),
    w: "272px",
  });
  const loanManageColumn = column<Row>({
    header: "",
    data: (data) => createManageLink(data.id),
    w: "272px",
  });

  return [idColumn, nameColumn, detailsColumn, loanManageColumn];
}

function createDetailsLink(id: string) {
  return <Link to={createFundDetailsUrl(id)}>Details</Link>;
}

function createManageLink(id: string) {
  return <Link to={createFundLoanManagementUrl(id)}>Loan Management</Link>;
}

function createRows(data: FundsPageFundsQuery | undefined): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...(data?.funds.map((f) => ({
      kind: "data" as const,
      id: f.id,
      data: f,
    })) || []),
  ];
}

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};
