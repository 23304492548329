import React, { createContext, useContext, useMemo, useState } from "react";

export type File = {
  name: string;
  progress: number;
};

export type Files = {
  [id: string]: File;
};

type UploaderContextProps = {
  files: Files;
  setFiles(files: Files | null): void;
  clearFiles: () => void;
};

export const UploaderContext = createContext<UploaderContextProps>({
  files: {},
  setFiles: () => {},
  clearFiles: () => {},
});

export function UploaderProvider({ children }: React.PropsWithChildren<unknown>) {
  const [files, setFiles] = useState({});

  function setFileState(newFiles: Files) {
    setFiles((previousFiles) => ({ ...previousFiles, ...newFiles }));
  }

  function clearFiles() {
    setFiles({});
  }

  const context = useMemo(() => ({ files, setFiles: setFileState, clearFiles }), [files]);

  return <UploaderContext.Provider value={context}>{children}</UploaderContext.Provider>;
}

export const useUploaderContext = () => {
  return useContext(UploaderContext);
};
