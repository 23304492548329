import { Css } from "@homebound/beam";
import { ReactNode } from "react";
// import { emptyCellDash } from "src/components/gridTableCells";
import { Maybe } from "src/generated/graphql-types";
import { useTestIds } from "src/hooks";
import { centsToDollars, formatNumberToString } from "src/utils";

const emptyCellDash = "-";

export type PriceProps = {
  id?: string;
  valueInCents: Maybe<number>;
  dropZero?: boolean;
  displayDirection?: boolean;
  // trims trailing zeros after decimal point
  trim?: boolean;
};

export function Price(props: PriceProps) {
  const { id, valueInCents, dropZero = false, displayDirection = false, trim = false } = props;
  const [rootId] = useTestIds(id || "price");
  const isNegative = !!valueInCents && valueInCents < 0;
  const prefix = isNegative ? "-$" : displayDirection ? "+$" : "$";
  const styles = displayDirection && !isNegative ? Css.green800.$ : isNegative ? Css.red600.$ : undefined;
  const formattedPrice =
    typeof valueInCents !== "number" || (dropZero && valueInCents === 0)
      ? emptyCellDash
      : `${prefix}${formatNumberToString(centsToDollars(Math.abs(valueInCents)), trim)}`;
  return (
    <span {...rootId} css={styles}>
      {formattedPrice}
    </span>
  );
}

export function priceCellContent(amount: number): ReactNode {
  return <Price valueInCents={amount} />;
}
