import { BoundDateFieldProps, DateField, useTestIds } from "@homebound/beam";
import { FieldState } from "@homebound/form-state";
import { capitalCase } from "change-case";
import { Observer } from "mobx-react";
import { DateOnly } from "src/utils/dates";

type BoundDateOnlyFieldProps = Omit<BoundDateFieldProps, "field"> & {
  field: FieldState<DateOnly | null | undefined>;
};

/** Adapts BoundDateField to our DateOnly. */
export function BoundBeamDateField(props: BoundDateOnlyFieldProps) {
  const {
    field,
    label = defaultLabel(field.key),
    onChange = (value) => {
      field.set(value === null || value === undefined ? undefined : new DateOnly(value));
    },
    readOnly,
    ...others
  } = props;
  const testId = useTestIds(props, field.key);
  return (
    <Observer>
      {() => (
        <DateField
          label={label}
          readOnly={readOnly ?? field.readOnly}
          value={field.value?.date || undefined}
          onChange={(value) => onChange(value)}
          errorMsg={field.touched ? field.errors.join(" ") : undefined}
          onBlur={() => field.blur()}
          onFocus={() => field.focus()}
          {...testId}
          {...others}
        />
      )}
    </Observer>
  );
}

function defaultLabel(id: string): string {
  return capitalCase(id).replace(/Ids?$/, "");
}
