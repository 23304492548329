import {
  column,
  GridCellContent,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { endOfMonth, isEqual } from "date-fns";
import { priceCellContent } from "src/components";
import { FundOverviewPageLotFragment } from "src/generated/graphql-types";
import { DateOnly, formatYearFirst } from "src/utils/dates";

export type EquityFinancingTableProps = {
  lots: FundOverviewPageLotFragment[];
  fundId: string;
};

export function EquityFinancingTable(props: EquityFinancingTableProps) {
  const { lots } = props;
  // Get the end of each of the months here so the tables are comparable
  const endOfAllMonths = lots
    .flatMap((lot) => [
      ...lot.partitions.flatMap((partition) =>
        partition.payments.flatMap((payment) => new DateOnly(endOfMonth(payment.paymentDate))),
      ),
      ...lot.partitions.flatMap((partition) =>
        partition.draws.flatMap((draw) => new DateOnly(endOfMonth(draw.creditFacilityDraw.drawDate))),
      ),
      lot.acquisitionDate ? new DateOnly(endOfMonth(lot.acquisitionDate)) : null,
    ])
    .sort()
    .compact()
    .filter((date, index, array) => !isEqual(date, array[index - 1])); // remove duplicates, for some reason .unique() doesn't work here, probably something around isEquals vs ===

  const commonColumns = [
    column<Row>({
      header: () => "Lot ID",
      data: ({ id }) => id,
      sticky: "left",
      w: "60px",
    }),

    column<Row>({
      header: () => "Lot Address",
      data: ({ address }) => address?.street1,
      sticky: "left",
      w: "150px",
    }),

    column<Row>({
      header: () => "Blueprint Id(s)",
      data: ({ partitions }) => partitions.map((partition) => partition.blueprintProjectId).join(", "),
      sticky: "left",
      w: "150px",
    }),
  ];

  return (
    <>
      <span>Cumulative Amount Spent on Lots by Month</span>
      <GridTable
        id="cumulativeSpentTable"
        rowStyles={rowStyles}
        columns={createAmountUsedLotColumns(endOfAllMonths, commonColumns)}
        rows={createLotRows(lots)}
        fallbackMessage="Amound used by date for lots will appear here."
        sorting={{ on: "client" }}
        stickyHeader
      />
      <span>Cumulative Amount of Debt on Lots by Month</span>
      <GridTable
        id="debtUsedTable"
        rowStyles={rowStyles}
        columns={createDebtUsedLotColumns(endOfAllMonths, commonColumns)}
        rows={createLotRows(lots)}
        fallbackMessage="Amound of debt for lots will appear here."
        sorting={{ on: "client" }}
        stickyHeader
      />
      <span>Amount of Equity Used (Spent - Debt) on Lots by Month</span>
      <GridTable
        id="equityUsedTable"
        rowStyles={rowStyles}
        columns={createEquityUsedLotColumns(endOfAllMonths, commonColumns)}
        rows={createLotRows(lots)}
        fallbackMessage="Amound of equity (used - debt) for lots will appear here."
        sorting={{ on: "client" }}
        stickyHeader
      />
      <span>Amount of Equity Financing Cost attributable Per Month (non-cumulative)</span>
      <GridTable
        id="pikTable"
        rowStyles={rowStyles}
        columns={createEquityFinancingLotColumns(endOfAllMonths, commonColumns)}
        rows={createLotRows(lots)}
        fallbackMessage="Amound of Equity Financing Costs for lots will appear here."
        sorting={{ on: "client" }}
        stickyHeader
      />
    </>
  );
}

type Row = SimpleHeaderAndData<FundOverviewPageLotFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function createLotRows(lots: FundOverviewPageLotFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(lots);
}

function createAmountUsedLotColumns(endOfAllMonths: DateOnly[], commonColumns: GridColumn<Row>[]): GridColumn<Row>[] {
  const monthlyDateColumns = endOfAllMonths.map((monthlyDate) => {
    return column<Row>({
      header: () => formatYearFirst(monthlyDate),
      data: (row) => amountSpentGridCellContent(row, monthlyDate),
      w: "100px",
    });
  });

  return [...commonColumns, ...monthlyDateColumns];
}

function amountSpent(row: FundOverviewPageLotFragment, monthlyDate: DateOnly): number {
  const acquisitionPriceToInclude =
    row.acquisitionDate && row.acquisitionDate < monthlyDate
      ? row.purchasePriceInCents + row.opcoSiteAcquisitionMarkupInCents
      : 0;

  const totalPayments =
    row.partitions
      .flatMap((partition) => partition.payments)
      .filter((payment) => payment.paymentDate < monthlyDate)
      .map((payment) => payment.amountInCents)
      .sum() + acquisitionPriceToInclude;
  return totalPayments ?? 0;
}

function amountSpentGridCellContent(row: FundOverviewPageLotFragment, monthlyDate: DateOnly): GridCellContent {
  const totalAmountSpent = amountSpent(row, monthlyDate);
  return {
    alignment: "left",
    content: priceCellContent(totalAmountSpent),
    value: totalAmountSpent,
  };
}

function createDebtUsedLotColumns(endOfAllMonths: DateOnly[], commonColumns: GridColumn<Row>[]): GridColumn<Row>[] {
  const monthlyDateColumns = endOfAllMonths.map((monthlyDate) => {
    return column<Row>({
      header: () => formatYearFirst(monthlyDate),
      data: (row) => debtUsedAmounCellContent(row, monthlyDate),
      w: "100px",
    });
  });

  return [...commonColumns, ...monthlyDateColumns];
}

function debtUsedAmount(row: FundOverviewPageLotFragment, monthlyDate: DateOnly): number {
  const cumulativeDebtTaken = row.partitions
    .flatMap((partition) => partition.draws)
    .filter((draw) => draw.creditFacilityDraw.drawDate < monthlyDate)
    .map((draw) => draw.amountInCents)
    .sum();
  const cumulativeDebtPaidOff = row.partitions
    .flatMap((partition) => partition.draws)
    .flatMap((draw) => draw.payments)
    .filter((payment) => payment.creditFacilityPayment.paymentDate < monthlyDate)
    .map((payment) => payment.amountInCents)
    .sum();

  return cumulativeDebtTaken - cumulativeDebtPaidOff;
}

function debtUsedAmounCellContent(row: FundOverviewPageLotFragment, monthlyDate: DateOnly): GridCellContent {
  const outstandingDebt = debtUsedAmount(row, monthlyDate);
  return {
    alignment: "left",
    content: priceCellContent(outstandingDebt),
    value: outstandingDebt,
  };
}

function createEquityUsedLotColumns(endOfAllMonths: DateOnly[], commonColumns: GridColumn<Row>[]): GridColumn<Row>[] {
  const monthlyDateColumns = endOfAllMonths.map((monthlyDate) => {
    return column<Row>({
      header: () => formatYearFirst(monthlyDate),
      data: (row) => equityUsedAmountCellContent(row, monthlyDate),
      w: "100px",
    });
  });

  return [...commonColumns, ...monthlyDateColumns];
}

function equityUsedAmountCellContent(row: FundOverviewPageLotFragment, monthlyDate: DateOnly): GridCellContent {
  const equityUsedAmount = amountSpent(row, monthlyDate) - debtUsedAmount(row, monthlyDate);
  return {
    alignment: "left",
    content: priceCellContent(equityUsedAmount),
    value: equityUsedAmount,
  };
}

function equityFinancingCostCellContent(row: FundOverviewPageLotFragment, monthlyDate: DateOnly): GridCellContent {
  const lotPartitionEquityFinancingCosts = row.partitions
    .flatMap((partition) => partition.equityFinancingCosts)
    .filter((efc) => isEqual(endOfMonth(efc.interestDate), endOfMonth(monthlyDate)))
    .map((draw) => draw.interestAmountInCents)
    .sum();

  const lotEquityFinancingCosts = row.equityFinancingCosts
    .filter((efc) => isEqual(endOfMonth(efc.interestDate), endOfMonth(monthlyDate)))
    .map((draw) => draw.interestAmountInCents)
    .sum();

  const allEquityFinancingCost = lotPartitionEquityFinancingCosts + lotEquityFinancingCosts;

  return {
    alignment: "left",
    content: priceCellContent(allEquityFinancingCost),
    value: allEquityFinancingCost,
  };
}

function createEquityFinancingLotColumns(
  endOfAllMonths: DateOnly[],
  commonColumns: GridColumn<Row>[],
): GridColumn<Row>[] {
  const monthlyDateColumns = endOfAllMonths.map((monthlyDate) => {
    return column<Row>({
      header: () => formatYearFirst(monthlyDate),
      data: (row) => equityFinancingCostCellContent(row, monthlyDate),
      w: "100px",
    });
  });

  return [...commonColumns, ...monthlyDateColumns];
}
