import { generatePath } from "react-router-dom";
import {
  drawPath,
  drawRequestPath,
  fundLoanManagementPath,
  fundPath,
  invoicePath,
  lotPath,
  paymentPath,
} from "./routes/routesDef";

export function createLotDetailsUrl(lotId: string) {
  return generatePath(lotPath, { lotId });
}

export function createFundDetailsUrl(fundId: string) {
  return generatePath(fundPath, { fundId });
}

export function createFundLoanManagementUrl(fundId: string) {
  return generatePath(fundLoanManagementPath, { fundId });
}

export function createDrawDetailsUrl(drawId: string) {
  return generatePath(drawPath, { drawId });
}

export function createDrawRequestDetailsUrl(drawRequestId: string) {
  return generatePath(drawRequestPath, { drawRequestId });
}

export function createPaymentDetailsUrl(paymentId: string) {
  return generatePath(paymentPath, { paymentId });
}

export function createInvoiceDetailsUrl(invoiceId: string) {
  return generatePath(invoicePath, { invoiceId });
}
