import { newComparator } from "./arrays";

export function fail(message?: string): never {
  throw new Error(message || "Failed");
}

export const sum = (a: number, b: number) => a + b;

// Even though we use Math.max, this declaration is more reduce-friendly.
export const max = (a: number, b: number) => Math.max(a, b);

/** Returns a `T` that actually has no keys defined; very unsafe but nice to have for default form inputs. */
export function empty<T>(): T {
  return {} as any as T;
}

// A nice type alias for hooks instead of the [T, Dispatch<...>] nonsense.
export type StateHook<T> = [T, (value: T) => void];

export function getOrElse<T extends Record<K, V>, K extends keyof any, V>(record: T, key: K, fn: (key: K) => V): V;
export function getOrElse<T extends Map<K, V>, K extends keyof any, V>(record: T, key: K, fn: (key: K) => V): V;
export function getOrElse<T extends Record<K, V> | Map<K, V>, K extends keyof any, V>(
  recordOrMap: T,
  key: K,
  fn: (key: K) => V,
): V {
  if (recordOrMap instanceof Map) {
    const map = recordOrMap as Map<K, V>;
    return (
      map.get(key) ||
      (() => {
        const value = fn(key);
        map.set(key, value);
        return value;
      })()
    );
  } else {
    const record = recordOrMap as Record<K, V>;
    return (
      record[key] ||
      (() => {
        const value = fn(key);
        record[key] = value;
        return value;
      })()
    );
  }
}

export function groupBy<T, Y = T>(
  list: T[],
  fn: (x: T) => string,
  valueFn?: (x: T) => Y,
  sortFn?: (x: Y) => number | string,
): Record<string, Y[]> {
  const result: Record<string, Y[]> = {};
  list.forEach((o) => {
    const group = fn(o);
    if (result[group] === undefined) {
      result[group] = [];
    }
    result[group].push(valueFn === undefined ? (o as any as Y) : valueFn(o));
  });
  if (sortFn) {
    Object.keys(result).forEach((key) => {
      result[key].sort(newComparator(sortFn));
    });
  }
  return result;
}

/** Maps the values of an object to a new object. */
export function mapValues<T, U>(record: Record<string, T>, fn: (t: T) => U): Record<string, U> {
  return Object.fromEntries(Object.entries(record).map(([key, value]) => [key, fn(value)]));
}

type Builtin = Date | Function | Uint8Array | string | number | undefined | boolean;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends Record<string, any>
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>;

export function assertNever(x: never): never {
  throw new Error("Unexpected object: " + x);
}

export function zeroTo(n: number): number[] {
  return [...Array(n).keys()];
}

// Examples: pluralize(tacoCount, "taco"); pluralize(boxCount, "box", "boxes");
// Automatic "s" is for convenience in simple cases; when in doubt, supply the full plural noun
export function pluralize(count: number, noun: string, pluralNoun?: string): string {
  if (count === 1) return noun;
  return pluralNoun || `${noun}s`;
}

/** Casts `Object.keys` to "what it should be", as long as your instance doesn't have keys it shouldn't. */
export function safeKeys<T>(instance: T): (keyof T)[] {
  return Object.getOwnPropertyNames(instance) as any;
}

/** Casts `Object.entries` to "what it should be", as long as your record doesn't have keys it shouldn't. */
export function safeEntries<K extends keyof any, V>(record: Record<K, V>): [K, V][] {
  return Object.entries(record) as [K, V][];
}

export const objectId = (() => {
  let currentId = 0;
  const map = new WeakMap();
  return (object: object): number => {
    if (!map.has(object)) {
      map.set(object, ++currentId);
    }
    return map.get(object)!;
  };
})();

/** Returns the number suffix of a tagged Id ("p:1" -> 1) */
export function removeTag(id: string) {
  return Number(id.split(":").pop());
}

export function newPartial<T>(partial: DeepPartial<T>): T {
  return partial as any as T;
}

/** Constructs a type by extracting Keys of `T` that have a value of type `V` {@link https://stackoverflow.com/a/54520829} */
export type KeysMatching<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];

/** No operation function, can be used to default react props */
export function noop() {}

export function partition<T>(array: ReadonlyArray<T>, f: (el: T) => boolean): [T[], T[]] {
  const trueElements: T[] = [];
  const falseElements: T[] = [];

  array.forEach((el) => {
    if (f(el)) {
      trueElements.push(el);
    } else {
      falseElements.push(el);
    }
  });

  return [trueElements, falseElements];
}

export function unique<T>(array: ReadonlyArray<T>) {
  return [...new Set(array)];
}

/**
 * Dedupe an array of objects by a given object key
 * @param array An array of objects
 * @param key Object key used to find duplicates
 */
export function uniqueByKey<T extends object>(array: ReadonlyArray<T>, key: keyof T) {
  return [...new Map(array.map((item) => [item[key], item])).values()];
}

/** Smartly de tag ids only when its in a id form i:12 */
export function maybeDeTagId(id: string) {
  const untaggedId = id.match(/[^\d\W]+:(?<id>\d+)/)?.groups?.id;
  return untaggedId ?? id;
}

export function titleCase(str: string) {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export async function wait(seconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}
