import { Css, ScrollableContent } from "@homebound/beam";
import { useParams } from "react-router";
import { useFundLoanManagementFundQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { queryResult } from "src/utils/queryResult";
import { FundLoanManagementTabs } from "./FundLoanManagementTabs";

export function FundLoanManagementPage() {
  const { fundId } = useParams<{ fundId: string }>();
  const query = useFundLoanManagementFundQuery({ variables: { fundId } });

  return queryResult(query, {
    data: (data) => {
      const { funds } = data;
      const fund = funds[0];
      const title = `${fund.name}`;
      return (
        <>
          <PageHeader title={title} />
          <ScrollableContent>
            <div css={Css.df.py2.aifs.jcsb.$} data-testid="fundLoanManagementPage">
              <div css={Css.fg1.$}>
                <FundLoanManagementTabs fund={fund} />
              </div>
            </div>
          </ScrollableContent>
        </>
      );
    },
  });
}
