import {
  Button,
  column,
  dateColumn,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { Price } from "src/components";
import {
  FundLoanManagementPageDrawRequestFragment,
  useCreditFacilityDrawRequestCreatePollingQuery,
  useDeleteCreditFacilityDrawRequestMutation,
  useFundLoanManagementFundDrawRequestsQuery,
  useQueueJobsMutation,
} from "src/generated/graphql-types";
import { createDrawRequestDetailsUrl } from "src/RouteUrls";
import { formatWithYear } from "src/utils/dates";
import { Link, useHistory } from "react-router-dom";
import { queryResult } from "src/utils/queryResult";

export type FundDrawRequestsTableProps = {
  fundId: string;
};

export function FundDrawRequestsTable(props: FundDrawRequestsTableProps) {
  const { fundId } = props;

  const [deleteCreditFacilityDrawRequest] = useDeleteCreditFacilityDrawRequestMutation();
  const [queueJobs] = useQueueJobsMutation();
  const pollingCreateQuery = useCreditFacilityDrawRequestCreatePollingQuery({ variables: { fundId } });
  const query = useFundLoanManagementFundDrawRequestsQuery({ variables: { fundId } });

  const history = useHistory();

  async function handleDelete(id: string) {
    await deleteCreditFacilityDrawRequest({ variables: { input: { id } } });
    await query.refetch();
  }

  async function wait(seconds: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
  }

  return queryResult(query, ({ funds }) => {
    const drawRequests = funds[0].creditFacilityDrawRequests || [];
    const hasUndrawnRequests = drawRequests.some((draw) => !draw.creditFacilityDraw?.id);
    return (
      <>
        <GridTable
          id="drawsTable"
          rowStyles={rowStyles}
          columns={createDrawRequestColumns(handleDelete)}
          rows={createDrawRequestRows(drawRequests)}
          fallbackMessage="Draw Requests for this Fund will show here."
        />
        <Button
          label="Create Draw Request"
          variant="secondary"
          disabled={hasUndrawnRequests && fundId}
          onClick={async () => {
            console.log("Creating draw request with fundId " + fundId);
            // Queue the job to create the draw request
            await queueJobs({
              variables: { input: { name: "createCreditFacilityDrawRequest", payloads: [{ fundId }] } },
            });
            // Poll the server for the new draw request
            let newDrawRequestId = undefined;
            while (!newDrawRequestId) {
              await wait(5); // Wait 5 seconds between each poll
              try {
                console.log("Polling for new draw request");
                const newDrawRequests = (await pollingCreateQuery.refetch()).data?.creditFacilityDrawRequests || [];
                const newDrawRequest = newDrawRequests.first;
                newDrawRequestId = newDrawRequest?.id;
              } catch (e) {
                console.error(e);
              }
              console.log("New draw request ID", newDrawRequestId);
            }
            // Redirect to the new draw request
            history.push({
              pathname: createDrawRequestDetailsUrl(newDrawRequestId),
            });
          }}
        />
      </>
    );
  });
}

type Row = SimpleHeaderAndData<FundLoanManagementPageDrawRequestFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function createDrawRequestRows(draws: FundLoanManagementPageDrawRequestFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(draws);
}

function createDrawRequestColumns(handleDelete: {
  (id: string): Promise<void>;
  (arg0: string): any;
}): GridColumn<Row>[] {
  const idColumn = column<Row>({
    header: () => "Credit Facility Draw Request ID",
    data: ({ id }) => <Link to={createDrawRequestDetailsUrl(id)}>{id}</Link>,
  });

  const preparationDateColumn = dateColumn<Row>({
    header: () => "Preparation Date",
    data: ({ preparationDate }) => formatWithYear(preparationDate),
  });

  const amountColumn = column<Row>({
    header: () => "Amount Drawn",
    data: ({ amountInCents }) => getPriceComponent(amountInCents),
  });

  const drawIdColumn = column<Row>({
    header: () => "Draw ID",
    data: ({ creditFacilityDraw }) => creditFacilityDraw?.id || "None",
  });

  const deleteColumn = column<Row>({
    header: () => "Delete",
    data: ({ id, canDelete }) => {
      return (
        <IconButton
          icon="trash"
          tooltip="delete draw request"
          onClick={async () => {
            await handleDelete(id);
          }}
          disabled={!canDelete.allowed}
        />
      );
    },
  });

  return [idColumn, preparationDateColumn, amountColumn, drawIdColumn, deleteColumn];
}

function getPriceComponent(value: number | undefined) {
  return <Price valueInCents={value} dropZero />;
}
