import { TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import { FundOverviewPageFundFragment } from "src/generated/graphql-types";
import { EquityFinancingTable } from "./EquityFinancingTables";
import { FundDetails } from "./FundDetails";
import { FundMetrosTable } from "./FundMetrosTable";

import { LotsTable } from "./LotsTable";
import { PerformanceTable } from "./PerformanceTable";
import { UnderwritingSummaryTable } from "./UnderwritingSummaryTable";

export type FundOverviewTabsProps = {
  fund: FundOverviewPageFundFragment;
};

export function FundOverviewTabs({ fund }: FundOverviewTabsProps) {
  const [currentTab, setCurrentTab] = useState("FundDetails");

  const tabs = getTabs({ fund });

  return <TabsWithContent selected={currentTab} onChange={setCurrentTab} tabs={tabs} />;
}

function getTabs(props: FundOverviewTabsProps) {
  const { fund } = props;
  const { id } = fund;

  return [
    {
      name: "Fund Details",
      value: "FundDetails",
      render: () => <FundDetails fundData={fund} />,
    },
    {
      name: "Fund Metros",
      value: "FundMetros",
      render: () => <FundMetrosTable fund={fund} metros={fund.metros} />,
    },
    {
      name: "Investments",
      value: "Investments",
      render: () => <LotsTable lots={fund.lots} fundId={id} />,
    },
    {
      name: "Performance",
      value: "Performance",
      render: () => <PerformanceTable fund={fund} />,
    },
    {
      name: "Underwriting Summary",
      value: "UnderwritingSummary",
      render: () => <UnderwritingSummaryTable fund={fund} />,
    },
    {
      name: "Equity Usage Summary",
      value: "Equity Usage Summary",
      render: () => <EquityFinancingTable fundId={id} lots={fund.lots} />,
    },
  ];
}
