import { TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import { DrawRequestOverviewPageDrawRequestFragment } from "src/generated/graphql-types";
import { DrawRequestDetails } from "./DrawRequestDetails";
import { DrawRequestLotPartitionDrawRequestsTable } from "./DrawRequestLotPartitionDrawRequestsTable";

export type DrawRequestOverviewTabsProps = {
  drawRequest: DrawRequestOverviewPageDrawRequestFragment;
};

export function DrawRequestOverviewTabs({ drawRequest }: DrawRequestOverviewTabsProps) {
  const [currentTab, setCurrentTab] = useState("Activity Feed");

  const tabs = getTabs({ drawRequest });

  return <TabsWithContent selected={currentTab} onChange={setCurrentTab} tabs={tabs} />;
}

function getTabs(props: DrawRequestOverviewTabsProps) {
  const { drawRequest } = props;

  return [
    {
      name: "Draw Request Information",
      value: "DrawRequestInformation",
      render: () => <DrawRequestDetails drawRequest={drawRequest} />,
    },
    {
      name: "Lot Partition Draw Requests",
      value: "LotPartitionDrawRequests",
      render: () => <DrawRequestLotPartitionDrawRequestsTable drawRequest={drawRequest} />,
    },
  ];
}
