import { useEffect, useRef } from "react";

export function useUnload(callback: (e: BeforeUnloadEvent) => void) {
  const cb = useRef(callback);

  useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener("beforeunload", onUnload);
    return () => window.removeEventListener("beforeunload", onUnload);
  }, [cb]);
}
