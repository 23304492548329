import {
  Button,
  column,
  dateColumn,
  GridCellContent,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
  useGridTableApi,
} from "@homebound/beam";
import { priceCellContent } from "src/components";
import {
  FundLoanManagementPageLotFragment,
  FundLoanManagementPageLotPartitionFragment,
} from "src/generated/graphql-types";
import { formatWithYear } from "src/utils/dates";

export type LoanInterestSummaryTableProps = {
  lots: FundLoanManagementPageLotFragment[];
  fundId: string;
};

export function LoanInterestSummaryTable(props: LoanInterestSummaryTableProps) {
  const { lots } = props;
  const lotPartitions = (lots.flatMap((lot) => lot.partitions) || []).compact();
  const tableApi = useGridTableApi<Row>();

  return (
    <>
      <GridTable
        id="loanInterestSummaryTable"
        rowStyles={rowStyles}
        columns={createColumns()}
        rows={createLotRows(lotPartitions)}
        fallbackMessage="Interest Summary lot partitions will appear here."
        sorting={{ on: "client" }}
        stickyHeader
        api={tableApi}
      />
      <Button onClick={() => tableApi.downloadToCsv("LoanInterestSummary.csv")} label="Download CSV" />
    </>
  );
}

type Row = SimpleHeaderAndData<FundLoanManagementPageLotPartitionFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function createLotRows(lots: FundLoanManagementPageLotPartitionFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(lots);
}

function createSharedColumns(): GridColumn<Row>[] {
  const idColumn = column<Row>({
    header: () => "Lot Partition ID",
    data: ({ id }) => id,
    sticky: "left",
  });

  const addressColumn = column<Row>({
    header: () => "Lot Address",
    data: ({ lot }) => lot.address?.street1,
    sticky: "left",
  });

  const blueprintIdColumn = column<Row>({
    header: () => "Blueprint Id",
    data: ({ blueprintProjectId }) => blueprintProjectId,
    sticky: "left",
  });

  return [idColumn, addressColumn, blueprintIdColumn];
}

function createColumns(): GridColumn<Row>[] {
  const sharedColumns = createSharedColumns();

  const totalAmountBorrowedColumn = column<Row>({
    header: () => "Amount Borrowed",
    data: ({ draws }) =>
      amountGridCellContent(
        draws
          .filter((draw) => draw.amountInCents > 0)
          .map((draw) => draw.amountInCents)
          .sum(),
      ),
  });

  const totalAmountPaidOffColumn = column<Row>({
    header: () => "Amount Paid Off",
    data: ({ draws }) =>
      amountGridCellContent(
        draws
          .map((draw) =>
            draw.payments
              .filter((payment) => payment.amountInCents > 0)
              .map((payment) => payment.amountInCents)
              .sum(),
          )
          .sum(),
      ),
  });

  const unpaidBalanceColumn = column<Row>({
    header: () => "Unpaid Balance",
    data: ({ draws }) => amountGridCellContent(draws.map((draw) => draw.remainingAmountInCents).sum()),
  });

  const totalInterestPaidColumn = column<Row>({
    header: () => "Total Interest Paid",
    data: ({ draws }) =>
      amountGridCellContent(
        draws.map((draw) => draw.invoices.map((invoice) => invoice.interestAmountInCents).sum()).sum(),
      ),
  });

  const lastInterestDate = dateColumn<Row>({
    header: () => "Last Interest Date",
    data: ({ draws }) => {
      const lastDate = draws
        .flatMap((draw) =>
          draw.invoices
            .filter((invoice) => invoice.interestAmountInCents !== 0)
            .map((invoice) => invoice.creditFacilityInvoice.invoiceDate),
        )
        .max();
      return lastDate ? formatWithYear(lastDate) : "-";
    },
  });

  return [
    ...sharedColumns,
    totalAmountBorrowedColumn,
    totalAmountPaidOffColumn,
    unpaidBalanceColumn,
    totalInterestPaidColumn,
    lastInterestDate,
  ];
}

function amountGridCellContent(amount: number): GridCellContent {
  return {
    alignment: "left",
    content: priceCellContent(amount),
    value: amount / 100, // Because this is the value printed by the CSV download
  };
}
