import {
  Button,
  column,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { UppyFile } from "@uppy/core";
import { useState } from "react";
import { UppyUploader } from "src/components/UppyUploader";
import {
  LotOverviewPageLotDocumentFragment,
  LotOverviewPageLotFragment,
  SaveDocumentInput,
  useLotDocumentsQuery,
  useSaveDocumentsMutation,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils/queryResult";

export type LotDocumentsTableProps = {
  lot: LotOverviewPageLotFragment;
};

export function LotDocumentsTable(props: LotDocumentsTableProps) {
  const { lot } = props;
  const [allowDocRedirect, setAllowDocRedirect] = useState(true);

  const query = useLotDocumentsQuery({ variables: { lotId: lot.id }, skip: !lot.id });

  const [saveDocument] = useSaveDocumentsMutation();

  // Once an asset has been uploaded, create a document with an asset
  async function handleOnFinish(file: UppyFile) {
    // This is to prevent multiple file uploads to redirect one after each other
    if (allowDocRedirect) {
      setAllowDocRedirect(false);
    }
    const saveDocumentsInput: [SaveDocumentInput] = [
      {
        parentId: lot.id,
        asset: {
          contentType: file.type,
          fileName: file.name,
          s3Key: file.meta.s3Key as string,
          sizeInBytes: file.size,
        },
      },
    ];
    await saveDocument({ variables: { input: { documents: saveDocumentsInput } } });
    await query.refetch();
  }

  return queryResult(query, ({ lots }) => {
    const documents = lots[0].documents || [];
    return (
      <>
        <UppyUploader onFinish={handleOnFinish} onComplete={() => setAllowDocRedirect(true)} />

        <GridTable
          id="lotPartitionsTable"
          rowStyles={rowStyles}
          columns={createLotDocumentColumns(/* formState */)}
          rows={createLotDocumentRows(documents)}
          fallbackMessage="Documents for this Lot will show here."
        />
      </>
    );
  });
}

type Row = SimpleHeaderAndData<LotOverviewPageLotDocumentFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function createLotDocumentRows(documents: LotOverviewPageLotDocumentFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(documents);
}

function createLotDocumentColumns(): GridColumn<Row>[] {
  const idColumn = column<Row>({
    header: () => "Document ID",
    data: ({ id }) => id,
    w: "100px",
  });

  const nameColumn = column<Row>({
    header: () => "Document Name",
    data: ({ asset }) => asset.fileName,
    w: "100px",
  });

  const downloadColumn = column<Row>({
    header: () => "",
    data: ({ asset }) => {
      return (
        <Button
          label="Download"
          variant="tertiary"
          onClick={() => {
            // Open the download url in a new tab
            window.open(asset.downloadUrl, "_blank", "noopener,noreferrer");
          }}
        />
      );
    },
  });

  return [idColumn, nameColumn, downloadColumn];
}
