import { Css, ScrollableContent } from "@homebound/beam";
import { useParams } from "react-router";
import { useDrawRequestOverviewDrawRequestQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { formatWithYear } from "src/utils/dates";
import { queryResult } from "src/utils/queryResult";
import { DrawRequestOverviewTabs } from "./DrawRequestOverviewTabs";

export function DrawRequestOverviewPage() {
  const { drawRequestId } = useParams<{ drawRequestId: string }>();
  const query = useDrawRequestOverviewDrawRequestQuery({ variables: { drawRequestId } });

  return queryResult(query, {
    data: (data) => {
      const { creditFacilityDrawRequests } = data;
      const drawRequest = creditFacilityDrawRequests[0];
      const title = `${drawRequest.fund.name} - DrawRequest ${drawRequest.id} - ${formatWithYear(
        drawRequest.preparationDate,
      )}`;
      return (
        <>
          <PageHeader title={title} />
          <ScrollableContent>
            <div css={Css.df.py2.aifs.jcsb.$} data-testid="drawRequestOverviewPage ">
              <div css={Css.fg1.$}>
                <DrawRequestOverviewTabs drawRequest={drawRequest} />
              </div>
            </div>
          </ScrollableContent>
        </>
      );
    },
  });
}
