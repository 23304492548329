import { TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import { LotOverviewPageLotFragment } from "src/generated/graphql-types";
import { InvestorMarginDetails } from "./InvestorMarginDetails";
import { LotPartitionsTable } from "./LotPartitionsTable";
import { LotDocumentsTable } from "./LotDocumentsTable";

export type LotOverviewTabsProps = {
  lot: LotOverviewPageLotFragment;
};

export function LotOverviewTabs({ lot }: LotOverviewTabsProps) {
  const [currentTab, setCurrentTab] = useState("Activity Feed");

  const tabs = getTabs({ lot });

  return <TabsWithContent selected={currentTab} onChange={setCurrentTab} tabs={tabs} />;
}

function getTabs(props: LotOverviewTabsProps) {
  const { lot } = props;

  return [
    {
      name: "Investor Margin Details",
      value: "InvestorMarginDetails",
      render: () => <InvestorMarginDetails marginData={lot}></InvestorMarginDetails>,
    },
    {
      name: "Partitions",
      value: "Partitions",
      render: () => <LotPartitionsTable lotPartitions={lot.partitions} lot={lot}></LotPartitionsTable>,
    },
    {
      name: "Documents",
      value: "Documents",
      render: () => <LotDocumentsTable lot={lot}></LotDocumentsTable>,
    },
  ];
}
