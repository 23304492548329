import {
  BoundNumberField,
  column,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import {
  FundLoanManagementPageFundFragment,
  FundLoanManagementPageLotPartitionFragment,
  Maybe,
  SaveLotPartitionInput,
  useSaveLotPartitionsMutation,
} from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";

export type LotPartitionAppraisalsTableProps = {
  fund: FundLoanManagementPageFundFragment;
};

type FormInput = SaveLotPartitionInput & {
  lotPartitions: Maybe<SaveLotPartitionInput[]>;
};

export function LotPartitionAppraisalsTable(props: LotPartitionAppraisalsTableProps) {
  const { fund } = props;
  const lotPartitions = (fund.lots.flatMap((lot) => lot.partitions) || [])
    .compact()
    .sortBy((lp) => lp.blueprintProjectId || "")
    .reverse();

  const formConfig: ObjectConfig<FormInput> = useMemo(
    () => ({
      id: { type: "value" },
      lotPartitions: {
        type: "list",
        config: lotPartitionConfig,
      },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      input: lotPartitions,
      map: mapToForm,
    },
    autoSave: saveLotPartitions,
  });
  const [saveLotPartition] = useSaveLotPartitionsMutation();

  async function saveLotPartitions(formState: ObjectState<FormInput>) {
    const changedLotPartitions =
      formState.changedValue.lotPartitions?.filter(
        (lp) => lp.appraisalValueInCents !== undefined || lp.appraisalDate !== undefined,
      ) || [];
    await Promise.all(
      changedLotPartitions.map(async (lp) =>
        saveLotPartition({
          variables: { input: { lotPartitions: [{ ...lp }] } },
        }),
      ),
    );
  }

  return (
    <>
      <GridTable
        id="lotPartitionsTable"
        rowStyles={rowStyles}
        columns={createLotPartitionAppraisalColumns(formState)}
        rows={createLotPartitionAppraisalRows(lotPartitions)}
        fallbackMessage="Partitions for this Lot will show here."
        sorting={{ on: "client" }}
      />
    </>
  );
}

type Row = SimpleHeaderAndData<FundLoanManagementPageLotPartitionFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function createLotPartitionAppraisalRows(
  lotPartitions: FundLoanManagementPageLotPartitionFragment[],
): GridDataRow<Row>[] {
  return simpleDataRows(lotPartitions);
}

function createLotPartitionAppraisalColumns(formState: ObjectState<FormInput>): GridColumn<Row>[] {
  const lotPartitionIdColumn = column<Row>({
    header: () => "Id",
    data: ({ id }) => id,
    sticky: "left",
    w: "100px",
  });

  const blueprintIdColumn = column<Row>({
    header: () => "BlueprintProjectId",
    data: ({ blueprintProjectId }) => blueprintProjectId,
    sticky: "left",
    w: "100px",
  });

  const addressColumn = column<Row>({
    header: () => "Lot Address",
    data: ({ lot }) => lot.address?.street1,
    sticky: "left",
    w: "300px",
  });

  const appraisalColumn = column<Row>({
    header: () => "Appraised Value",
    data: (row) => {
      const formStateField = formState.lotPartitions.rows.find((lp) => lp.id.value === row.id);
      return {
        value: () => row.appraisalValueInCents,
        content: () => <BoundNumberField type="cents" field={formStateField!.appraisalValueInCents} />,
        tooltip: "The most recent appraised value for this property",
      };
    },
    w: "200px",
  });

  const appraisalDateColumn = column<Row>({
    header: () => "Appraised Date",
    data: (row) => {
      const formStateField = formState.lotPartitions.rows.find((lp) => lp.id.value === row.id);
      return {
        value: () => row.appraisalDate,
        content: () => <BoundBeamDateField field={formStateField!.appraisalDate} />,
        tooltip: "The date of the most recent appraisal for this property",
      };
    },
    w: "150px",
  });

  return [lotPartitionIdColumn, blueprintIdColumn, addressColumn, appraisalColumn, appraisalDateColumn];
}

function mapToForm(lotPartitions: FundLoanManagementPageLotPartitionFragment[]): FormInput {
  return {
    lotPartitions: mapLotPartitionsToForm(lotPartitions),
  };
}

export function mapLotPartitionsToForm(lotPartitions: Maybe<FundLoanManagementPageLotPartitionFragment[]>) {
  return lotPartitions?.map((lp) => {
    return {
      id: lp.id,
      appraisalValueInCents: lp.appraisalValueInCents,
      appraisalDate: lp.appraisalDate ? new DateOnly(lp.appraisalDate) : undefined,
    };
  });
}

type FormValueRevision = Pick<SaveLotPartitionInput, "id" | "appraisalValueInCents"> & {
  appraisalDate: DateOnly | null | undefined;
};

export const lotPartitionConfig: ObjectConfig<FormValueRevision> = {
  id: { type: "value" },
  appraisalValueInCents: { type: "value" },
  appraisalDate: { type: "value" },
};
