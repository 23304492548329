import { BoundDateField, Button, Css, useModal } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import {
  DrawRequestOverviewPageDrawRequestFragment,
  useDrawRequestOverviewDrawRequestQuery,
  useSaveCreditFacilityDrawRequestMutation,
} from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";
import { BillExportModal } from "../billExportModal/BillExportModal";
import { CreateDrawModal } from "../createDrawModal/CreateDrawModal";
import { getGraphQLBaseUrl } from "src/context";

type DrawRequestDetailsProps = {
  drawRequest: DrawRequestOverviewPageDrawRequestFragment;
};

export type SaveDrawRequestDetails = {
  id: string;
  preparationDate: Date | null | undefined;
};

export function DrawRequestDetails({ drawRequest }: DrawRequestDetailsProps) {
  const query = useDrawRequestOverviewDrawRequestQuery({
    variables: { drawRequestId: drawRequest.id },
  });
  const [saveDrawRequestMutation] = useSaveCreditFacilityDrawRequestMutation();
  const { openModal } = useModal();

  const formConfig: ObjectConfig<SaveDrawRequestDetails> = useMemo(
    () => ({
      id: { type: "value" },
      preparationDate: { type: "value", rules: [required] },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      query,
      map: ({ creditFacilityDrawRequests: [drawRequest] }) => ({
        ...drawRequest,
        preparationDate: drawRequest.preparationDate.date,
      }),
    },
    autoSave: saveDrawRequest,
  });

  async function saveDrawRequest() {
    await saveDrawRequestMutation({
      variables: {
        input: {
          id: formState.value.id,
          preparationDate: formState.value.preparationDate ? new DateOnly(formState.value.preparationDate) : undefined,
        },
      },
    });
  }

  return (
    <div css={Css.df.aic.$}>
      <div css={Css.df.wPx(400).fs0.fdc.p2.bgGray100.$}>
        <BoundDateField
          field={formState.preparationDate}
          label="Preparation Date"
          readOnly={!drawRequest.canEdit.allowed}
        />
        <Button
          label="Create Bill Export For Draw"
          variant="secondary"
          onClick={() =>
            openModal({
              content: (
                <BillExportModal
                  fundId={drawRequest.fund.id}
                  endDate={drawRequest.preparationDate}
                  minimumBillAmountInCents={5000_00}
                />
              ),
            })
          }
        />
        <Button
          label="Download Cost Item Details"
          variant="secondary"
          onClick={`${getGraphQLBaseUrl()}/csv?type=drawRequestCostItems&drawRequestId=${drawRequest.id}`}
        />
        <Button
          label="Create Draw From Request"
          variant="secondary"
          onClick={() =>
            openModal({
              content: <CreateDrawModal fundId={drawRequest.fund.id} drawRequestId={drawRequest.id} />,
            })
          }
        />
      </div>
    </div>
  );
}
